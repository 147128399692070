import { request } from '@/service/request'
import { request4 } from '@/service/request4'
import axios from 'axios'
import { MAP_KEY } from '@/common/constant'
import Vue from 'vue'
import fetchJsonp from 'fetch-jsonp'
import cache from "@/utils/cache"

export function getCityList(id = null) {
  if (id) {
    let url = `https://apis.map.qq.com/ws/district/v1/getchildren?key=${MAP_KEY}&id=${id}&output=jsonp`
    return new Promise((resolve, reject) => {
      fetchJsonp(url, {
        jsonpCallback: 'cb'
      })
        .then((response) => {
          return response.json()
        })
        .then((json) => {
          resolve(json)
        })
    })
  } else {
    let url = `https://apis.map.qq.com/ws/district/v1/getchildren?key=${MAP_KEY}&output=jsonp`
    return new Promise((resolve, reject) => {
      fetchJsonp(url, {
        jsonpCallback: 'cb'
      })
        .then((response) => {
          return response.json()
        })
        .then((json) => {
          resolve(json)
        })
    })
  }
}

export function getKeywordList(keyword, region) {
  let url = `https://apis.map.qq.com/ws/place/v1/suggestion?key=${MAP_KEY}&keyword=${keyword}&region=${region}&output=jsonp`

  return new Promise((resolve, reject) => {
    fetchJsonp(url, {
      jsonpCallback: 'cb'
    })
      .then((response) => {
        return response.json()
      })
      .then((json) => {
        resolve(json)
      })
  })
}

export function saveShopBed(obj) {
  return request({
    url: '/insert-shop-room',
    method: 'post',
    data: obj
  })
}

export function saveShopPic(obj) {
  return request({
    url: '/insert-shop-pic',
    method: 'post',
    data: obj
  })
}

export function saveShopInfo(obj) {
  return request4({
    url: '/shop/web/createShop',
    data: obj,
    method: 'post'
  })
}

export function getShopList(obj) {
  let adminInfo = cache.getCache('adminInfo')
  let userId = adminInfo.userId
  return request({
    url: '/shop-list',
    data: {...obj,userId},
    method: 'post'
  })
}
//新获取门店列表
export function getAllShopList(obj) {
  return request4({
    url: '/shop/web/getShopList',
    data: obj,
    method: 'post'
  })
}

export function getShopDetail(id) {
  return request4({
    url: `/shop/web/getShop?shopId=${id}`,
    method: 'get'
  })
}

export function getShopPicList(obj) {
  return request({
    url: '/get-pic',
    data: obj,
    method: 'post'
  })
}

export function updateShopInfo(obj) {
  return request4({
    url: '/shop/web/editShop',
    data: obj,
    method: 'PUT'
  })
}

export function removeGoodsPic(obj) {
  return request({
    url: '/update-pic',
    method: 'post',
    data: obj
  })
}

export function stopShop(obj) {
  return request({
    url: '/update-shop-status',
    method: 'post',
    data: obj
  })
}

export function getShopBedNum(shopId) {
  return request({
    url: `/bed-qty/${shopId}`,
    method: 'post'
  })
}

export function getShopBedList(shopId) {
  return request({
    url: `/room-list/${shopId}`,
    method: 'post'
  })
}

export function getShopListPic(relateId, relateKind) {
  return request({
    url: `/get-cover/${relateId}/3`,
    method: 'post'
  })
}

export function getShopTagName(tagId) {
  return request({
    url: `/get-tag-name/${tagId}`,
    method: 'post'
  })
}

export function removeShopRoom(obj) {
  return request({
    url: `/update-room-status`,
    method: 'post',
    data: obj
  })
}

export function updateShopRoom(obj) {
  return request({
    url: `/update-room-para`,
    method: 'post',
    data: obj
  })
}
export function getShopDownList() {
  return request4({
    url: `/shop/web/getShopDownList`,
    method: 'get'
  })
}
//美团授权绑定
export function shopMeiTuanAuth(obj) {
  return request4({
    url: `/shop/web/shopMeiTuanAuth`,
    method: 'post',
    data: obj
  })
}
//获取美团的门店列表
export function getMeiTuanShopList() {
  return request4({
    url: `/shop/web/getMeiTuanShopList`,
    method: 'get'
  })
}
//获取抖音的门店列表 
export function getTiktokShopList() {
  return request4({
    url: `/shop/web/getTiktokShopList`,
    method: 'get'
  })
}
//获取待绑定门店列表
export function getBingShopList(obj) {
  return request4({
    url: `/shop/web/getBingShopList`,
    method: 'post',
    data: obj
  })
}
//绑定门店
export function bindShop(obj) {
  return request4({
    url: `/shop/web/bindShop`,
    method: 'post',
    data: obj
  })
}
//解除绑定
export function unBindShop(id) {
  return request4({
    url: `/shop/web/unBindShop?id=${id}`,
    method: 'get'
  })
}
//获取上一次的绑定时间
export function getLastAuthTime(id) {
  return request4({
    url: `/shop/web/getLastAuthTime`,
    method: 'get'
  })
}
//保存抖音账号id
export function saveTiktokAccountId(id) {
  return request4({
    url: `/shop/web/saveTiktokAccountId?accountId=${id}`,
    method: 'get'
  })
}