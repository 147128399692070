import emitter from '@/utils/eventbus'
import { tips } from '@/utils/tips'
import { getHexColor } from '@/utils/color'
import dayjs from 'dayjs'
import {
  addCard,
  cardServeBind,
  cardGiveawayBind,
  getAllCardList,
  setShopCardBind,
  getShopCardBindList,
  setCardShareInfo,
  getCardDetail,
  getCardServeList,
  getCardHandselList,
  removeCardServeBind,
  removeCardHandselBind,
  updateCardInfo,
  updateCardServeInfo,
  updateCardHandselInfo,
  getCardCategoryList,
  updateCardState,
  getCardCategory,
  batchSetCardState,
  saveShareDescInfo,
  saveCardShareInfo,
  getCardBuyShopNum,
  getCardBuyNum
} from '@/service/main/card'
import { getCardDetail2 } from '@/service/main/customer'

import { COMPANY_ID } from '@/common/constant'
import router from '@/router'
import { ElMessage } from 'element-plus'

const customerModule = {
  namespaced: true,
  state() {
    return {
      cardInfoList: [],
      cardInfoCount: 0,
      shopCardBindList: [],
      newAddCardId: '',
      cardDetail: '',
      cardHandselList: '',
      cardServeList: [],
      cardCategoryList: [],
      newUpdateCardId: '',
      cardCategory: []
    }
  },
  getters: {
    pageListData(state) {
      return (pageName) => {
        return state[`${pageName}List`]
      }
    },
    pageListCount(state) {
      return (pageName) => {
        return state[`${pageName}Count`]
      }
    }
  },
  mutations: {
    changeCardList(state, list) {
      state.cardInfoList = list
    },
    changeCardCount(state, count) {
      state.cardInfoCount = count
    },
    changeShopCardBindList(state, list) {
      state.shopCardBindList = list
    },
    changeNewAddCardId(state, info) {
      state.newAddCardId = info
    },
    changeCardDetaail(state, info) {
      state.cardDetail = info
    },
    changeCardHandselList(state, info) {
      state.cardHandselList = info
    },
    changeCardServeList(state, list) {
      state.cardServeList = list
    },
    changeCardCategoryList(state, list) {
      state.cardCategoryList = list
    },
    changeUpdateCardId(state, info) {
      state.newUpdateCardId = info
    },
    changeCardCategory(state, list) {
      state.cardCategory = list
    }
  },
  actions: {
    async addCardAction({ commit }, payload) {
      let obj = { ...payload }
      delete obj.date
      delete obj.handselList2
      let ruleForm = obj.ruleForm
      delete ruleForm.date
      let handselList = obj.handselList
      let serveList = obj.serveList

      ruleForm.cardBgcolor = getHexColor(ruleForm.cardBgcolor)

      if (ruleForm.timeLimitFlag == '2') {
        ruleForm.fixStartTime = dayjs(ruleForm.fixStartTime).format(
          'YYYY-MM-DD HH:mm:ss'
        )

        ruleForm.fixEndTime = dayjs(ruleForm.fixEndTime).format(
          'YYYY-MM-DD HH:mm:ss'
        )
      } else if (ruleForm.timeLimitFlag == '3') {
        ruleForm.timeLimitDays = ruleForm.timeLimitDays3
      } else if (ruleForm.timeLimitFlag == '4') {
        ruleForm.timeLimitDays = ruleForm.timeLimitDays4
      }

      if (ruleForm.timeLimitFlag != '2') {
        ruleForm.fixStartTime = null
        ruleForm.fixEndTime = null
      }

      delete ruleForm.timeLimitDays2
      delete ruleForm.timeLimitDays3
      delete ruleForm.timeLimitDays4
      const res = await addCard(ruleForm)
      if (res.code == 99) {
        ElMessage({
          message: res.msg,
          type: 'warning'
        })
        return
      }
      tips(res)
      emitter.emit('changeAddCardNextClick')
      emitter.emit('refreshCardPageContent')
      let cardId = res.data
      commit('changeNewAddCardId', cardId)
      serveList.forEach((elem) => {
        if (elem.paraIdList.length > 0) {
          Promise.all(
            elem.paraIdList.map(async (item) => {
              // console.log(item)
              let serveParams = {
                companyId: COMPANY_ID,
                serveId: item.serveId,
                qty: item.Qty,
                avaragePrice: (item.averagePrice / 100).toFixed(2),
                cardId,
                paraId: item.paraId
              }
              return await cardServeBind(serveParams)
            })
          ).then((resList) => {})
        }
      })

      handselList.forEach((elem) => {
        if (elem.paraIdList.length > 0) {
          Promise.all(
            elem.paraIdList.map(async (item) => {
              let params = {
                companyId: COMPANY_ID,
                giftKind: item.serveId ? 1 : 2,
                relateId: item.serveId ? item.serveId : item.goodId,
                qty: item.Qty,
                avaragePrice: item.salePrice,
                cardId,
                paraId: item.paraId
              }
              return await cardGiveawayBind(params)
            })
          ).then((resList) => {})
        }
      })
    },
    async updateCardInfoAction({ commit }, payload) {
      let obj = { ...payload }
      let ruleForm = obj.ruleForm
      ruleForm.handselList2
      let handselList = obj.handselList
      let serveList = obj.serveList
      let cardId = obj.cardId

      ruleForm.cardBgcolor = getHexColor(ruleForm.cardBgcolor)
      commit('changeUpdateCardId', cardId)
      ruleForm.timeLimitDays = 0
      if (ruleForm.date.length) {
        ruleForm.fixStartTime = dayjs(ruleForm.date[0]).format('YYYY-MM-DD HH:mm:ss')
        ruleForm.fixEndTime = dayjs(ruleForm.date[1]).format('YYYY-MM-DD HH:mm:ss')
        delete ruleForm.date
      }

      if (ruleForm.timeLimitFlag == '2') {
        ruleForm.fixStartTime = dayjs(ruleForm.fixStartTime).format(
          'YYYY-MM-DD HH:mm:ss'
        )

        ruleForm.fixEndTime = dayjs(ruleForm.fixEndTime).format(
          'YYYY-MM-DD HH:mm:ss'
        )
      } else if (ruleForm.timeLimitFlag == '3') {
        ruleForm.timeLimitDays = ruleForm.timeLimitDays3
      } else if (ruleForm.timeLimitFlag == '4') {
        ruleForm.timeLimitDays = ruleForm.timeLimitDays4
      }

      delete ruleForm.timeLimitDays2
      delete ruleForm.timeLimitDays3
      delete ruleForm.timeLimitDays4
      const res = await updateCardInfo(ruleForm)
      tips(res)
      emitter.emit('refreshCardPageContent')
      commit('changeNewAddCardId', cardId)

      serveList.forEach((elem) => {
        if (elem.paraIdList.length > 0) {
          Promise.all(
            elem.paraIdList.map(async (item) => {
              if (!item.isUpdate) {
                let serveParams = {
                  companyId: COMPANY_ID,
                  serveId: item.serveId,
                  qty: item.Qty,
                  avaragePrice: (item.averagePrice / 100).toFixed(2),
                  cardId,
                  paraId: item.paraId
                }
                return await cardServeBind(serveParams)
              }
            })
          ).then((resList) => {})
        }
      })

      handselList.forEach((elem) => {
        if (elem.paraIdList.length > 0) {
          Promise.all(
            elem.paraIdList.map(async (item) => {
              if (!item.isUpdate) {
                let params = {
                  companyId: COMPANY_ID,
                  giftKind: item.serveId ? 1 : 2,
                  relateId: item.serveId ? item.serveId : item.goodId,
                  qty: item.Qty,
                  avaragePrice: item.salePrice,
                  cardId,
                  paraId: item.paraId
                }
                return await cardGiveawayBind(params)
              }
            })
          ).then((resList) => {})
        }
      })
    },
    async getcardInfoListAction({ commit }, payload) {
      const res = await getAllCardList(payload)
      let list = res.data.list
      commit('changeCardList', list)
      commit('changeCardCount', Number(res.data.total))
    },
    async setShopCardBindAction({ commit }, payload) {
      const res = await setShopCardBind(...payload)
      tips(res)
      if (res.code == 0) {
        emitter.emit('changeCardRefreshDistributePage')
      }
    },
    async getShopCardBindList({ commit }, payload) {
      const res = await getShopCardBindList(payload)
      commit('changeShopCardBindList', res.data.list)
    },
    async setCardShareInfoAction({ commit, state }, payload) {
      let obj = { ...payload }

      if (obj.isUpdate) {
        delete obj.isUpdate
        obj.cardId = state.newUpdateCardId
      } else {
        obj.cardId = state.newAddCardId
      }
      await saveCardShareInfo({
        companyId: COMPANY_ID,
        cardId: obj.cardId,
        cardInfo: obj.cardInfo,
        shareWords: obj.shareWords
      })
      const res = await setCardShareInfo(obj)
      tips(res)
      if (res.code == 0) {
        router.replace('/main/card/list')
      }
    },
    async getCardDetailAction({ commit }, payload) {
      const res = await getCardDetail(payload)
      commit('changeCardDetaail', res.data)
    },
    async getCardDetailAction2({ commit }, payload) {
      const res = await getCardDetail2(payload)
      commit('changeCardDetaail', res.data)
    },
    async getCardHandselListAction({ commit }, payload) {
      if (payload[0] == undefined || payload[0] == '') return
      const handselRes = await getCardHandselList(...payload)
      commit('changeCardHandselList', handselRes.data)
    },
    async getCardServeListAction({ commit }, payload) {
      if (payload[0] == undefined || payload[0] == '') return
      // console.log(payload)
      const serveRes = await getCardServeList(...payload)
      commit('changeCardServeList', serveRes.data.list)
    },
    async removeCardServeBindAction({ commit }, payload) {
      const res = await removeCardServeBind(...payload)
      // tips(res)
    },
    async removeCardhandselBindAction({ commit }, payload) {
      const res = await removeCardHandselBind(...payload)
      tips(res)
    },

    async updateCardServeInfoAction({ commit }, payload) {
      const res = await updateCardServeInfo(payload)
      tips(res)
    },
    async updateCardHandselInfoAction({ commit }, payload) {
      const res = await updateCardHandselInfo(payload)
      tips(res)
    },
    async getCardCategoryListAction({ commit }, payload) {
      const res = await getCardCategoryList(payload)
      commit('changeCardCategoryList', res.data.list)
    },
    async updateCardStateAction({ commit }, payload) {
      const res = await updateCardState(...payload)
      tips(res)
      if (res.code == 0) {
        emitter.emit('refreshCardPageContent')
      }
    },
    async getCardCategoryAction({ commit }, payload) {
      const res = await getCardCategory(COMPANY_ID)
      commit('changeCardCategory', res.data.list)
    },
    async batchSetCardStateAction({ commit }, payload) {
      const res = await batchSetCardState(payload)
      tips(res)
      if (res.code == 0) {
        emitter.emit('refreshCardPageContent')
      }
    },
    async saveShareDescInfoAction({ commit }, payload) {
      const res = await saveShareDescInfo(payload)
      tips(res)
    }
  }
}

export default customerModule
